import { AxiosError } from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export const showResponseErrorMessage = (error: AxiosError): void => {
  if (error) {
    if (error.response?.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};
